import * as React from 'react'
import { Vector } from '@superlogica/design-ui'
import { Container } from 'react-awesome-styled-grid'

import * as S from './Header.styled'
import { Link } from 'components/helpers'

const Header: React.FC = () => (
  <S.Wrapper id="inicio">
    <Container>
      <S.Content>
        <S.Brand>
          <Link to="/" title="Superlógica">
            <Vector name="brand-color" width="100%" height="100%" />
          </Link>
        </S.Brand>
      </S.Content>
    </Container>
  </S.Wrapper>
)

export default Header
