import React from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'

import * as S from './Heading.styled'

const Heading: React.FC = ({ children }) => (
  <S.Wrapper>
    <Container>
      <Row justify="center">
        <Col xs={12} md={10}>
          <S.InnerWrapper>{children}</S.InnerWrapper>
        </Col>
      </Row>
    </Container>
  </S.Wrapper>
)

export default Heading
