import styled, { css } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

export const InnerWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-top: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 50vw;
    height: 10px;
    bottom: -10px;
    display: block;
  }

  ${({ theme: { colors } }) => css`
    border-bottom: 10px solid ${parseColor(colors.sonic300)};

    &:before {
      left: -50vw;
      background-color: ${parseColor(colors.sonic400)};
    }

    &:after {
      right: -50vw;
      background-color: ${parseColor(colors.sonic600)};
    }
  `}

  ${media.lessThan('md')} {
    grid-template-columns: 1fr;
    padding-top: 40px;
  }
`

export const Wrapper = styled.header`
  overflow: hidden;

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.sonic500)};
  `}
`
