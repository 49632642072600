import React, { ReactNode } from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'
import { Block, Title } from '@superlogica/design-ui'

import * as S from './Hero.styled'

interface HeroProps {
  title: string
  subtitle: string
  edition: string
  image: ReactNode
}

const Hero: React.FC<HeroProps> = ({ title, edition, subtitle, image }) => (
  <S.Wrapper>
    <Container>
      <Row justify="center">
        <Col xs={12} md={10}>
          <S.InnerWrapper>
            <Block marginBottom={{ xxxs: 32, md: 0 }}>
              <Title size="display05" uppercase font="heading" color="sonic100">
                {edition}
              </Title>
              <Title size="display01" font="heading" color="babar0">
                {title}
              </Title>
              <Title size="display04" font="heading" color="warning">
                {subtitle}
              </Title>
            </Block>
            <Block maxWidth={{ xxs: '100%', lg: '470px' }}>{image}</Block>
          </S.InnerWrapper>
        </Col>
      </Row>
    </Container>
  </S.Wrapper>
)

export default Hero
