import styled from 'styled-components'

export const Brand = styled.div`
  display: flex;
  align-items: center;
  width: 124px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  overflow: hidden;
`
