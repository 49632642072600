module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/buildhome/repo/src/layouts/Default/index.ts"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"web":[{"name":"proxima-nova","file":"https://use.typekit.net/dhj7cep.css"},{"name":"paralucent","file":"https://use.typekit.net/kiy2wkh.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","trailingSlashes":true,"crumbLabelUpdates":[{"pathname":"/condominios","crumbLabel":"Condomínios"},{"pathname":"/condominios/servicos-financeiros/inadimplencia","crumbLabel":"Inadimplência Zero"},{"pathname":"/condominios/servicos-financeiros/bpo-de-cobrancas","crumbLabel":"BPO de Cobranças"},{"pathname":"/condominios/servicos-financeiros/cartao","crumbLabel":"Cartão"},{"pathname":"/condominios/servicos-financeiros/conta-digital","crumbLabel":"Conta Digital"},{"pathname":"/imobiliarias","crumbLabel":"Imobiliárias"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Superlógica","short_name":"Superlógica","start_url":"/","background_color":"#006aff","theme_color":"#006aff","display":"minimal-ui","icon":"/opt/buildhome/repo/src/assets/images/favicon.svg","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f545a2e32015766c3483a9992d024580"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
