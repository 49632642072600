import * as React from 'react'
import { ThemeProvider } from '@superlogica/design-ui'
import { GlobalStyle } from 'styles'

import theme from 'styles/theme.json'
import { Link } from 'components/helpers'
import { Footer, Header } from 'components/common'
import vectors from 'assets/svg/vectors'
import { ConfigProvider, HeaderProvider } from 'contexts'

import * as S from './Default.styles'

const DefaultLayout: React.FC = ({ children }) => (
  <ConfigProvider>
    <ThemeProvider theme={theme} svgLibrary={{ vectors }}>
      <GlobalStyle />
      <HeaderProvider>
        <Header
          notification={{
            bgColor: 'babar1000',
            headline: 'O que nos faz diferente?',
            url: {
              tag: Link,
              weight: 'bold',
              to: '/manifesto',
              label: 'Descubra'
            }
          }}
        />
      </HeaderProvider>
      <S.Main role="main">{children}</S.Main>
      <Footer />
    </ThemeProvider>
  </ConfigProvider>
)

export default DefaultLayout
